import { useEffect } from "react";
import photo from "../static/foto.jpg";
import PortoCanal from "../static/portocanal.mp4";
import PortoCanalPoster from "../static/portocanal.png";
import JusticaTV from "../static/justicatv.mp4";
import JusticaTVPoster from "../static/justicatv.png";
import Livro from "../static/livroVidaEconomica.jpg";
import JornalVidaEconomica from "../static/jornalvidaeconomica.jpg";
import ProcedimentoDisciplinar from "../static/procedimentodisciplinar.jpg";
import VidaJudiciaria from "../static/vidajudiciaria.jpg";
import JornalVidaEconomicaPDF from "../static/jornalvidaeconomica.pdf";
import ProcedimentoDisciplinarPDF from "../static/procedimentodisciplinar.pdf";
import VidaJudiciariaPDF from "../static/vidajudiciaria.pdf";
//ReactGA.pageview("/apresentacao");

const Presentation = function() {
  useEffect(() => {
    document.title = "Apresentação | João Vilas Boas Sousa Advogados";
  }, []);

  return (
    <div className="content">
      <div className="landingpagetopcurriculum">
        <div className="pagetextcontentcurriculum">
          <h1>Currículo</h1>
          <img
            src={photo}
            alt="joaovilasboassousafoto"
            className="profilephoto"
          />
        </div>
        <div className="curriculum">
          <h2>Mestre João Vilas Boas Sousa</h2>
          <p>
            É licenciado em Direito pela UCP – Porto no ano de 2002,
            pós-graduado em Direito do Trabalho pela UCP – Porto no ano de 2004,
            pós-graduado em Gestão Estratégica de Recursos Humanos pelo ISMAI –
            Porto, no ano de 2006 e, Mestre em Direito do Trabalho pela UCP –
            Lisboa, no ano de 2012.
          </p>
          <p>
            Exerce Advocacia desde o ano de 2003, tanto a nível de clientela
            privada como pública (apoio judiciário). Desde o ano de 2005 exerceu
            igualmente os cargos de Chefe de Relações Laborais e Diretor de
            Recursos Humanos em várias empresas privadas e multinacionais sendo
            atualmente Assessor Jurídico e de Recursos Humanos em várias firmas.
          </p>

          <p>
            Formador de Direito do Trabalho em diversas empresas privadas e
            convidado como palestrante em diversas conferências e seminários
            desde o ano de 2006.
          </p>

          <p>
            No ano de 2012 colaborou na 3ª edição do livro Direito do Trabalho
            em 100 Quadros da editora Vida Económica; no ano de 2014 publicou o
            livro O Procedimento Disciplinar para Aplicação de Sanções
            Conservatórias da editora Vida Económica e no ano de 2016 colaborou
            na edição do livro Dicionário Crime, Justiça e Sociedade, das
            Edições Sílabo.
          </p>

          <p>
            Associado e colaborador da Associação de Jovens Juslaboralistas de
            Portugal. Vogal da Delegação da Ordem dos Advogados de Vila Nova de
            Gaia (2014-2017). Vice-presidente da Assembleia Geral da LUSAPME –
            Associação das Pequenas e Médias Empresas (2015-2019). Advogado do
            Sindicato dos Trabalhadores da Indústria de Hotelaria, Turismo,
            Restaurantes e similares do Norte (2015-2019).
          </p>
          <p>
            Patrono do estágio de Advocacia a Advogados Estagiários. Professor
            convidado sobre temas de Direito do Trabalho aos alunos da
            Licenciatura e de Pós-graduação.
          </p>
          <p>
            <a href="https://www.oa.pt/cd/Conteudos/Contactos/lista_contactos.aspx?sidc=31690&idc=5902&idsc=1022&npage=3">
              Vogal do Conselho de Deontologia da Ordem dos Advogados
            </a>
            , Conselho Regional do Porto da Ordem dos Advogados.
          </p>
        </div>
      </div>
      <div className="talks">
        <h2>Publicações</h2>
        <h3>Livros</h3>
        <h4>
          <i>
            "O procedimento disciplinar para aplicação de sanções
            conservatórias"
          </i>{" "}
          - João Vilas Boas Sousa, 2014.
        </h4>
        <a
          href="http://livraria.vidaeconomica.pt/juridico/1112-o-procedimento-disciplinar-para-aplicacao-de-sancoes-conservatorias-9789727889143.html"
          alt="livraria vida economica"
        >
          <img src={Livro} alt="livro" className="book" />
        </a>
      </div>

      <div className="talks">
        <h2>Comunicação Social</h2>
        <h3>Artigos</h3>
        <div className="articles">
          <div>
            <h4>
              "Formação ajuda empresas a garantir ambientes laborais saudáveis e
              seguros" - Jornal Vida Económica (2020)
            </h4>
            <a href={JornalVidaEconomicaPDF} title="Abrir PDF">
              <img
                src={JornalVidaEconomica}
                alt="artigo jornal vida economica"
              />
            </a>
          </div>
          <div>
            <h4>"Procedimento disciplinar" - Jornal Vida Económica (2011)</h4>
            <a href={ProcedimentoDisciplinarPDF} title="Abrir PDF">
              <img
                src={ProcedimentoDisciplinar}
                alt="artigo jornal vida economica"
              />
            </a>
          </div>
          <div>
            <h4>
              "Revisão do código de trabalho foi deficiente e complicada" - Vida
              Judiciária (2009)
            </h4>
            <a href={VidaJudiciariaPDF} title="Abrir PDF">
              <img src={VidaJudiciaria} alt="vida judiciaria" />
            </a>
          </div>
        </div>
        <h3>Televisão</h3>
        <h4>Porto Canal</h4>
        <video controls width="560">
          <source
            src={PortoCanal}
            type="video/mp4"
            poster={PortoCanalPoster}
            preload="metadata"
          />
          <p>O seu browser na suporta HTML5.</p>
        </video>
        <h4>Justiça TV</h4>
        <video controls width="560">
          <source
            src={JusticaTV}
            type="video/mp4"
            poster={JusticaTVPoster}
            preload="metadata"
          />
          <p>O seu browser na suporta HTML5.</p>
        </video>
        <h3>
          Entrevista sobre Assédio Moral e Sexual no Local de Trabalho no dia
          28/11/2023
        </h3>

        <iframe
          width="100%"
          height="166"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
	  title="soundcloudinterview"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1683176640&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        ></iframe>
        <div
          style={{
            fontSize: "10px",
            color: "#cccccc",
            lineBreak: "anywhere",
            wordBreak: "normal",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontFamily:
              "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
            fontWeight: 100,
          }}
        >
          <a
            href="https://soundcloud.com/jvbs-361595613"
            title="jvbs"
            target="_blank"
	    rel="noreferrer"
            style={{ color: "#cccccc", textDecoration: "none" }}
          >
            jvbs
          </a>{" "}
          ·{" "}
          <a
            href="https://soundcloud.com/jvbs-361595613/dr-joao-vilas-boas-parte-de-entrevista-sobre-assedio-moral-e-sexual-no-local-de-trabalho"
            title="Dr. João Vilas Boas: Parte de entrevista sobre Assédio Moral e Sexual no Local de Trabalho"
            target="_blank"
	    rel="noreferrer"
            style={{ color: "#cccccc", textDecoration: "none" }}
          >
            Dr. João Vilas Boas: Parte de entrevista sobre Assédio Moral e
            Sexual no Local de Trabalho
          </a>
        </div>

        <p>
          A entrevista completa está disponível no{" "}
          <a href="https://soundcloud.com/jvbs-361595613/entrevista-assedio-moral-e-sexual-no-local-de-trabalho#t=20m31s">
            Soundcloud
          </a>
          .
        </p>
      </div>
      <div className="talks">
        <h2>Conferências</h2>
        <h3>
          Ordem dos Advogados - As Últimas Alterações ao Código do Trabalho
        </h3>

        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/sWV_CjT1pYQ"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Presentation;
