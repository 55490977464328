import { useEffect } from "react";
import { Link } from "react-router-dom";
import porto4k from "../static/porto_alt4k.jpg";
import portofhd from "../static/porto_altfhd.jpg";
import portohd from "../static/porto_althd.jpg";
import porto1600 from "../static/porto_alt1600.jpg";
import porto1366 from "../static/porto_alt1366.jpg";
import porto1024 from "../static/porto_alt1024.jpg";
import porto768 from "../static/porto_alt768.jpg";
import porto640 from "../static/porto_alt640.jpg";

const Home = function() {
  useEffect(() => {
    document.title = "João Vilas Boas Sousa Advogados";
  }, []);

  const changeHeight = function() {
    const overlaydom = document.querySelector(".overlay");
    if (overlaydom !== null) {
      const desiredHeight = document.querySelector(".landingpagetop img")
        .clientHeight;
      const heightstring = `${desiredHeight}px`;
      overlaydom.style.height = heightstring;
      // overlaydom.style.height = "84vh";
      setTimeout(() => {
        const overlaytext = document.querySelector(".overlaytext");
        const textheight = desiredHeight - 0.07 * desiredHeight;
        const textheightstring = `${textheight}px`;
        overlaytext.style.height = textheightstring;
        overlaytext.style.display = "flex";
      }, 1000);
    }
  };

  useEffect(() => {
    setTimeout(changeHeight, 1000);
  }, []);

  return (
    <div className="content">
      <div className="landingpagetop">
        <img
          srcSet={`${porto4k} 3840w, ${portofhd} 1920w, ${portohd} 1280w, ${porto1600} 1600w, ${porto1366} 1366w, ${porto1024} 1024w, ${porto768} 768w, ${porto640} 640w`}
          sizes="(max-width: 639px) 640px, (min-width: 3839px) 3840px, 1920px, 1280px, 1600px, 1366px, 1024px, 768px"
          src={portofhd}
          alt="porto"
          className="homeimage"
        />
      </div>
      <div className="overlay"></div>
      <div className="overlaytext">
        <div>
          <h1>João Vilas Boas Sousa</h1>
          <ul className="homepagelist">
            <li>
              <h3>Advogado com Mestrado em Direito do Trabalho</h3>
            </li>
            <li>
              <h3>
                Gestor de Recursos Humanos com Pós-Graduação em Gestão
                Estratégica de RH
              </h3>
            </li>

            <li>
              <h3>
                Autor de livros na área do Direito do Trabalho e Gestão de RH
              </h3>
            </li>
            <li>
              <h3>Formador com CPP e Conferencista</h3>
            </li>
          </ul>
        </div>
        <div className="homepageoverlayfooter">
          <div className="homepagemissiondiv">
            <div className="homepagemission">
              <p>Em defesa dos interesses e direitos dos clientes.</p>
            </div>
            <div className="homepagemission">
              <p>Serviço personalizado e acompanhamento contínuo.</p>
            </div>
            <div className="homepagemission">
              <p>
                Formação contínua aos trabalhadores, chefias e administração.
              </p>
            </div>
          </div>
          <div className="homecontact">
            <Link to="/contacto">Contacto</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
