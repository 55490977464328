import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import axios from "axios";
import Logo from "../static/logo_joao.JPG";
import WhatsAppLogo from "../static/icons8-whatsapp.svg";
//ReactGA.pageview("/contacto");

const Contacts = function() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmittedSuccessful, setFormSubmittedSuccessful] = useState(false);
  const [customername, setCustomerName] = useState("");
  const [customeremail, setCustomerEmail] = useState("");
  const [customertelephone, setCustomerTelephone] = useState("");
  const [messageSubject, setMessageSubject] = useState("");
  const [messageContent, setMessageContent] = useState("");
  useEffect(() => {
    document.title = "Contacto | João Vilas Boas Sousa Advogados";
  }, []);

  const recaptchaRef = React.createRef();

  const handleChange = function(event) {
    switch (event.target.id) {
      case "name":
        setCustomerName(event.target.value);
        break;
      case "email":
        setCustomerEmail(event.target.value);
        break;
      case "telephone":
        setCustomerTelephone(event.target.value);
        break;
      case "subject":
        setMessageSubject(event.target.value);
        break;
      case "message":
        setMessageContent(event.target.value);
        break;
      default:
        console.log("bot");
    }
  };

  const handleSubmit = async function(event) {
    event.preventDefault();
    if (event.target.querySelector('input[name="specialinput"]').value !== "") {
      return;
    }
    const recaptchatoken = await recaptchaRef.current.executeAsync();
    axios
      .post("./verify.php", {
        token: recaptchatoken,
      })
      .then((resp) => {
        const respData = resp.data;
        const respSuccess = respData["success"];
        if (respSuccess) {
          fetch(event.target.action, {
            method: "POST",
            body: new URLSearchParams(new FormData(event.target)),
          });
          setFormSubmitted(true);
          setFormSubmittedSuccessful(true);
        } else {
          alert("Envie um para o endereço indicado.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //       const recaptchatoken = await recaptchaRef.current.executeAsync();
  //   axios
  //     .post("./verify.php", {
  //       token: recaptchatoken,
  //     })
  //     .then((resp) => {
  //       const respData = resp.data;
  //       const respSuccess = respData["success"];
  //       if (respSuccess) {
  //         fetch(event.target.action, {
  //           method: "POST",
  //           body: new URLSearchParams(new FormData(event.target)),
  //         })
  //           .then((respForm) => {
  //             // console.log("respForm");
  //             setFormSubmitted(true);
  //             if (respForm) {
  //               console.log("true");
  //               setFormSubmittedSuccessful(true);
  //             } else {
  //               setFormSubmittedSuccessful(false);
  //             }
  //             return respForm;
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //           });
  //       } else {
  //         alert("Envie um para o endereço indicado."); //mudar
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const createemail = function(event) {
    const target = event.target;
    target.href = "mailto:joaovilasboassousa-10961p@adv.oa.pt";
  };
  return (
    <div className="content contentcontact">
      <h1>Contactos</h1>
      <div className="contactpage">
        <div className="logoforlargescreen">
          <img src={Logo} alt="logo" className="contactcardlogo" />
        </div>
        <div className="contactpageleft">
          <div className="contacttopcomplete">
            <div className="logoforsmallscreen">
              <img src={Logo} alt="logo" className="contactcardlogo" />
            </div>
            <div className="contacttop">
              <h2> E-mail | Telefone</h2>
              <p className="contactitem">
                <i className="las la-envelope"></i>{" "}
                <a href="mailto:" onClick={createemail}>
                  joaovilasboassousa-10961p
                  <span style={{ display: "none" }}>boo</span>@adv.oa.pt
                </a>
              </p>
              <div>
                <div>
                  <p className="contactitem">
                    Chamada para rede móvel nacional:
                  </p>
                  <p className="contactitem">
                    <i className="las la-mobile"></i>
                    <a href="tel:+351919997301">+351 919 997 301</a>
                    <span> | </span>
                    {/* <i className="lab la-whatsapp whastappcolor"></i> */}
                    <img
                      src={WhatsAppLogo}
                      alt="whastapp"
                      className="whatsapplogo"
                    />
                    <span> </span>
                    <a href="https://wa.me/351919997301">+351 919 997 301</a>
                  </p>
                </div>
                <div>
                  <p className="contactitem">
                    Chamada para rede fixa nacional:
                  </p>
                  <p className="contactitem">
                    <i className="las la-phone-alt"></i>
                    <a href="tel:+351223242883">+351 223 242 883</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="contactform">
            <h2>Formulário de contacto</h2>
            {!formSubmitted && !formSubmittedSuccessful && (
              <form
                id="contactform"
                /* action="https://hooks.zapier.com/hooks/catch/12404127/bkyo4i0/" */
                /* action="./mail_pear_alt.php" */
                action="https://formspree.io/f/xjvllzyq"
                method="POST"
                onSubmit={handleSubmit}
              >
                <label htmlFor="name">Nome*:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={customername}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="email">E-mail*:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={customeremail}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="telephone">Telefone*:</label>
                <input
                  type="tel"
                  id="telephone"
                  name="telephone"
                  value={customertelephone}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="subject">
                  Assunto* (máximo 100 caracteres):
                </label>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  value={messageSubject}
                  onChange={handleChange}
                  maxLength="50"
                  required
                />
                <label htmlFor="message">Mensagem*:</label>
                <textarea
                  name="message"
                  id="message"
                  required
                  maxLength="800"
                  rows="10"
                  value={messageContent}
                  onChange={handleChange}
                ></textarea>
                <input
                  type="text"
                  name="specialinput"
                  id="specialinput"
                  className="specialinput"
                />
                <div>
                  <p className="fillme">
                    * Campos de preenchimento obrigatório
                  </p>
                </div>
                <button type="submit">Enviar</button>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6Lco56sfAAAAAPR0pYWWZUjlVJdsj5tNuxxXkmx4"
                />
              </form>
            )}
            {formSubmitted && formSubmittedSuccessful && (
              <div>
                <p>
                  <i className="las la-check"></i> Mensagem enviada! Obrigado.
                </p>
              </div>
            )}
            {formSubmitted && !formSubmittedSuccessful && (
              <div>
                <p>
                  <i className="las la-times"></i> Não foi possível submeter a
                  mensagem.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="contactpageright">
          <div className="contacttop">
            <h2>Localização</h2>
            <p className="contactitem">
              Av. da República nº 755 4º Andar, Sala 46
            </p>
            <p className="contactitem">4430-201 Vila Nova de Gaia</p>
          </div>
          <div className="googlemapsdiv">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1502.5635563677185!2d-8.6064!3d41.131751!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1ad58a99b178b02!2sJo%C3%A3o%20Vilas%20Boas%20Sousa%20-%20Advogado!5e0!3m2!1sen!2spt!4v1654024856004!5m2!1sen!2spt"
              width="525"
              height="375"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="morada"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
