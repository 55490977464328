import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navigation from "./components/Navigation.js";
import Home from "./components/Home.js";
import Presentation from "./components/Presentation.js";
import WorkAreas from "./components/WorkAreas.js";
import Contacts from "./components/Contacts.js";
import Teaching from "./components/Teaching.js";
import ReactGA from "react-ga4";
ReactGA.initialize("G-Y9G8ZCE7X5");

const App = function() {
  const { pathname, hash, key } = useLocation();
  const [year, setyear] = useState("");
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 350);
    }
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, [pathname, hash, key]); // do this on route change

  useEffect(() => {
    if (year === "") {
      const today = new Date();
      setyear(today.getFullYear());
    }
  }, [year]);

  return (
    <div>
      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/apresentacao" element={<Presentation />} />
        <Route path="/areas-actuacao" element={<WorkAreas />} />
        <Route path="/formacao" element={<Teaching />} />
        <Route path="/contacto" element={<Contacts />} />
      </Routes>
      <footer>
        <p>
          {year} <i className="las la-copyright"></i> João Vilas Boas Sousa
          Advogados
        </p>
      </footer>
    </div>
  );
};

export default App;
