import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import text from "../content/areas.md";
import gavel4k from "../static/gavel_4k.jpg";
import gavelfhd from "../static/gavel_fhd.jpg";
import gavelhd from "../static/gavel_hd.jpg";
import gavel1600 from "../static/gavel_1600.jpg";
import gavel1366 from "../static/gavel_1366.jpg";
import gavel1024 from "../static/gavel_1024.jpg";
import gavel768 from "../static/gavel_768.jpg";
import gavel640 from "../static/gavel_640.jpg";
//ReactGA.pageview("/areas-actuacao");

const WorkAreas = function() {
  const [workareastext, setworkareastext] = useState({ textcontent: null });

  useEffect(() => {
    document.title = "Áreas de Actuação | João Vilas Boas Sousa Advogados";
  }, []);
  useEffect(() => {
    fetch(text)
      .then((response) => response.text())
      .then((textextracted) => {
        setworkareastext({ textcontent: textextracted });
      });
  }, []);

  return (
    <div className="content">
      <div className="landingpagetopworkareas">
        <h1 id="workareastitleexterior">Áreas de Actuação</h1>
        <div id="worksareastext" className="pagetextcontent teachingjustify">
          <h1 id="workareastitleinterior">Áreas de Actuação</h1>
          <ReactMarkdown children={workareastext.textcontent} />
          <p>
            Formação específica na área do Direito do Trabalho e Gestão
            Administrativa de RH, entre outras. Mais informações no{" "}
            <Link to="/formacao#catalogo">Catálogo de Formação</Link>.
          </p>
        </div>

        <img
          srcSet={`${gavel4k} 3840w, ${gavelfhd} 1920w, ${gavelhd} 1280w, ${gavel1600} 1600w, ${gavel1366} 1366w, ${gavel1024} 1024w, ${gavel768} 768w, ${gavel640} 640w`}
          sizes="(max-width: 640px) 640px, (min-width: 3840px) 3840px, 1920px, 1280px, 1600px, 1366px, 1024px, 768px"
          src={gavelfhd}
          alt="gavel"
          className="workareaphoto"
          id="workareasimage"
        />
      </div>
    </div>
  );
};

export default WorkAreas;
